<section class="header">
  <h1>
    <span>An overpriced car with a ‘for sale’ sticker is still an overpriced car.</span>
    <span>You know that.</span>
    <span>We know Forex.</span>
  </h1>
  <h2>
    <span>Stop paying retail Forex rates – </span>
    <span>TheCarSite gives you rates the Banks would die for.</span>
  </h2>
  <h2>Let’s level the playing field and play fair.</h2>
</section>
<section class="hero forexCalc">
  <div class="prodCard">
    <h3>Estimate Your Savings on Forex<br> using our simple calculator</h3>
    <div class="calc" [formGroup]="fflForm">
      <div class="tableHead" role="rowheader">
        Purchase currency
      </div>
      <mat-form-field appearance="outline">
        <mat-select #currency formControlName="currency">
          <mat-option value="gbp">&pound; - GBP</mat-option>
          <!--          <mat-option value="euro">&euro; - Euro</mat-option>-->
          <mat-option value="jpy">&yen; - Yen</mat-option>
        </mat-select>
      </mat-form-field>


      <div class="tableHead" role="rowheader">
        Average purchase price per car
      </div>
      <mat-form-field appearance="outline">
        @if (currency.value) {
          <span matTextPrefix>{{currency.value | currencySymbol }} &nbsp;</span>
        }
        <input matInput type="number" step="1000" min="0" formControlName="avgPrice" #avgPrice>
      </mat-form-field>


      <div class="tableHead" role="rowheader">
        Average cars purchased per month
      </div>
      <mat-form-field appearance="outline">
        <input matInput type="number" step="1" min="1" formControlName="avgCars" #avgCars>
      </mat-form-field>


      <div class="tableHead" [ngClass]="{hidden: !userService.admin}" role="rowheader">
        Current forex provider
      </div>
      <mat-form-field [ngClass]="{hidden: !userService.admin}" appearance="outline">
        <mat-select #curProvider value="High Street Banks">
          <mat-option value="High Street Banks">High Street Banks</mat-option>
          <mat-option value="Wise">Wise</mat-option>
          <mat-option value="TransferMate">TransferMate</mat-option>
          <mat-option value="Bank of Ireland">Bank of Ireland Global Markets</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="tableHead" [ngClass]="{hidden: !userService.admin}" role="rowheader">
        Date
      </div>
      <mat-form-field [ngClass]="{hidden: !userService.admin}" appearance="outline">
<!--        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>-->
        <input matInput [matDatepicker]="picker" [formControl]="datePicker" (focus)="picker.open()" [min]="minDate" [max]="maxDate" (dateChange)="dateChange($event)">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>
    @if (currency.value && avgPrice.value && avgCars.value && curProvider.value) {

      <div class="savings" role="table">

        <div class="tableHead" role="rowheader">Forex required per month</div>
        <div role="cell" class="cell">{{Number(avgPrice.value) * Number(avgCars.value) | currency: currency.value.toUpperCase() : 'symbol': '1.0-0' : 'en-IE'}}</div>

        <div class="tableHead" role="rowheader">Estimated cost using {{curProvider.value}}</div>
        <div role="cell" class="cell">{{providerCost(curProvider.value,currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))),Number(avgCars.value)) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE'}}</div>

        <div class="tableHead" role="rowheader">Estimated cost using theCarSite.com</div>
        <div role="cell" class="cell">{{currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))) * 1.0025 | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE'}}</div>

        <div class="tableHead " role="rowheader">Estimated savings per month using theCarSite.com</div>
        <div role="cell" class="cell ">{{(providerCost(curProvider.value,currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))),Number(avgCars.value)) - currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))) * 1.0025) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE'}}</div>
        <div class="tableHead statement" role="rowheader">Estimated savings per year using theCarSite.com</div>
        <div role="cell" class="cell statement">{{(providerCost(curProvider.value,currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))),Number(avgCars.value)) - currencyService.convertToEur(currency.value,(Number(avgPrice.value) * Number(avgCars.value))) * 1.0025) * 12 | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE'}}</div>

        <div class="footnote"><sup>*</sup>Currency exchange rate
          @if (currency.value == 'gbp') {
          EUR/GBP {{ currencyService.rates["gbp"] | number: "1.3-3" : "en" }}
          } @else if (currency.value == 'jpy') {
            EUR/JPY {{ currencyService.rates["jpy"] | number: "1.3-3" : "en" }}
          }
          , updated {{currencyService.rates.ts | date: 'medium' : undefined : 'en-IE'}}</div>

      </div>
    }
  </div>
  <div class="c2a">
    <h3>Sign up for Forex For Less now</h3>
    <button mat-raised-button color="primary" class="c2aLarge"  (click)="goto('corporate')">Corporate</button>
    <button mat-raised-button color="primary" class="c2aLarge" (click)="goto('individual')">Individual</button>
  </div>
</section>
