import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol',
  standalone: true
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value.toLowerCase()) {
      case 'gbp':
        return '£'
      case 'eur':
        return '€';
      case 'jpy':
        return '¥';
      default:
        return value;
    }
  }

}
