import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, KeyValuePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {RouterLink} from "@angular/router";
import {EquivCarsDialog} from "../equiv-cars/equiv-cars";
import {VehicleDataComponent} from "../../tables/vehicle-data/vehicle-data.component";
import {VrtDataComponent} from "../../tables/vrt-data/vrt-data.component";
import {formatRes} from "../../../pipes/format-res.pipe";
import {ApiResponse, ApiService} from "../../../services/api.service";
import {UserService} from "../../../services/user.service";
import { FcfService } from "../../../services/fcf.service";
import {MatAccordion, MatExpansionModule, MatExpansionPanel} from "@angular/material/expansion";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: "dialog-car-details",
  templateUrl: "../dialog-car-details/dialog-car-details.html",
  styleUrl: "../dialog-car-details/dialog-car-details.scss",
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    DecimalPipe,
    KeyValuePipe,
    NgForOf,
    NgIf,
    formatRes,
    MatButtonModule,
    MatDialogModule,
    NgClass,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinner,
    RouterLink,
    VehicleDataComponent,
    VrtDataComponent,
    MatAccordion,
    MatExpansionModule,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem
  ],
})
export class CarDetailsDialog {
  protected readonly Number = Number;
  protected loadingEquivs = false;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private apiService: ApiService,
    protected dialog: MatDialog,
    protected userService: UserService
  ) {}

  async showEquivs(id: number) {
    this.loadingEquivs = true;
    const equivs = <ApiResponse>await this.apiService.getEquivs(id).catch(e => console.error(e));
    this.loadingEquivs = false;
    if (equivs?.data) {
      this.dialog.open(EquivCarsDialog, {
        data: {
          data: this.data,
          equivs: equivs.data,
        },
      });
    } else {
      this.snackBar.open(
        "We could not find any equivalent vehicles currently on the Irish market",
        undefined,
        {
          panelClass: "snack-error",
          duration: 5000,
        }
      );
    }
  }

  async maskEntry(data: any) {
    await this.apiService.maskEntry(data.id);
    data.masked = data.masked ? null : "masked";
    this.dialog.closeAll();
  }

  protected readonly FcfService = FcfService;
}
