import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: "root"
})
export class CurrencyService {
  public rates: any = {
    eur: 1, jpy: 161.174, gbp: 0.847591
  };

  constructor(private apiService: ApiService) {
    this.getRates()
      .catch(e => console.error(e));
  }

  public gbpEur(priceGBP: number): number {
    return (priceGBP / this.rates["gbp"]) * 1.0025; // Add 0.25 % comission
  }

  public async getRates(date: Date = new Date()) {
    const res = await this.apiService.getCurrencyRates(date)
      .catch(e => console.error(e));
    if (res?.success && res.data["rates"]) {
      this.rates = res.data["rates"];
    }
  }

  convertToEur(from: string, amount: number): number {
    if (!this.rates[from]) {
      return 0;
    }
    return amount / this.rates[from];
  }
}
