import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ProductsComponent} from "../products/products.component";
import {StatbitsComponent} from "../statbits/statbits.component";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {MatOption} from "@angular/material/autocomplete";
import {RouterLink} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSelect} from "@angular/material/select";
import {AccessDialogComponent} from "../dialogs/access-dialog/access-dialog.component";
import {
  SubscriptionRequiredDialogComponent
} from "../dialogs/subscription-required-dialog/subscription-required-dialog.component";
import {CurrencyService} from "../../services/currency.service";
import {UserService} from "../../services/user.service";
import {MatDialog} from "@angular/material/dialog";
import {CurrencySymbolPipe} from "../../pipes/currency-symbol.pipe";
import {ReviewsComponent} from "../reviews/reviews.component";
import {MatDatepickerInputEvent, MatDatepickerModule} from "@angular/material/datepicker";
import { provideNativeDateAdapter} from "@angular/material/core";

@Component({
  selector: 'app-ffl-content',
  standalone: true,
  imports: [ProductsComponent, StatbitsComponent, NgOptimizedImage, MatOption, RouterLink, ReactiveFormsModule, NgClass, MatFormField, MatPrefix, MatInput, MatSelect, MatButton, CurrencySymbolPipe, CurrencyPipe, DecimalPipe, DatePipe, ReviewsComponent, MatDatepickerModule],
  providers: [provideNativeDateAdapter()],
  templateUrl: './ffl-content.component.html',
  styleUrl: './ffl-content.component.scss'
})
export class FflContentComponent implements OnInit{
  @Input() currency: string | undefined;
  @Input() avgPrice: number | undefined;
  @Input() avgCars: number | undefined;
  datePicker = new FormControl(new Date());
  minDate = new Date(2024, 0, 1);
  maxDate = new Date();
  constructor(protected currencyService: CurrencyService, protected userService: UserService, private dialog: MatDialog) {
    this.fflForm = new FormGroup({
      currency: new FormControl("", [Validators.required]),
      avgPrice: new FormControl("", [Validators.required]),
      avgCars: new FormControl("", [Validators.required]),
    });
  }

  protected readonly Number = Number;
  fflForm: FormGroup;
  ngOnInit() {
    if (this.currency) {
      this.fflForm.get('currency')?.setValue(this.currency);
    }
    if (this.avgPrice) {
      this.fflForm.get('avgPrice')?.setValue(this.avgPrice);
    }
    if (this.avgCars) {
      this.fflForm.get('avgCars')?.setValue(this.avgCars);
    }
  }

  protected providerCost(provider: string, amount: number, transactions: number = 1): number {
    let fee = 0;
    let comission = 1;
    switch (provider) {
      case "Wise":
        comission = 1.0033;
        break;
      case "TransferMate":
        comission = 1.004;
        fee = transactions; // They charge 1 GBP/EUR per transaction
        break;
      case "Bank of Ireland":
        comission = 1.03;
        break;
      default:
        comission = 1.025;
        break;
    }
    return amount * comission + fee;
  }

  goto(dest: string) {
    if (!this.userService.isLoggedIn()) {
      this.dialog.open(AccessDialogComponent,{
        data: {"feature": "Forex For Less"},
        backdropClass: 'backdrop-blur'
      });
      return;
    } else if (!this.userService.hasAccess("ffl")) {
      this.dialog.open(SubscriptionRequiredDialogComponent,{
        data: {"feature": "Forex For Less"}
      });
      return;
    }
    let link;
    switch (dest) {
      case "corporate":
        link ="https://onboarding.paydirect.io/tcs/forms/corporate";
        break;
      case "individual":
        link = "https://onboarding.paydirect.io/tcs/forms/individual";
        break;
      default:
        throw new Error(`${dest} is an invalid destination`);
    }
    window.open(link,"_blank","noopener");
  }

  async dateChange($event: MatDatepickerInputEvent<Date>) {
    // We need to refresh the rates with the new date
    const newDate = $event.value;
    if (newDate) {
      await this.currencyService.getRates(newDate);
    }
  }
}
