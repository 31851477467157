<h2 mat-dialog-title>{{ data.data.make }} {{ data.data.model }}</h2>
<mat-dialog-content>
  <div role="table" class="searchResTable valsRight">
    <div role="cell" class="columnheader">Source</div>
    <div role="cell" class="columnheader">Year</div>
    <div role="cell" class="columnheader">Mileage</div>
    <div role="cell" class="columnheader">Price</div>
    <ng-container *ngFor="let res of data.equivs">
      <div role="cell" class="value"><a [href]="FcfService.getSource(res).url" target="_blank" rel="noopener noreferrer" class="source">{{ FcfService.getSource(res).source ?? res.source }}</a></div>
      <div role="cell" class="value">{{ res.yearOfRegistration }}</div>
      <div role="cell" class="value">{{ res.kms | number }} km</div>
      <div role="cell" class="value euro">{{ res.price | number: '1.2-2' }}</div>
    </ng-container>
  </div>
</mat-dialog-content>
