<section class="header">
  <h1>The Most Accurate and UpToDate Data.</h1>
  <h2>Superior Car History Checks Made Easy.</h2>
</section>
<section class="hero">
  <div class="search-container noprint">
    <div class="search">
      <app-vrm-search mode="cyc" #vrmSearch [disabled]="searching"></app-vrm-search>
      <mat-slide-toggle #financeCheck (change)="toggleFinance($event)" [checked]="getFinancePref()">Finance checks (+1 credit)</mat-slide-toggle>
      <mat-progress-spinner *ngIf="searching" diameter="30" mode="indeterminate"></mat-progress-spinner>
      <p *ngIf="searching && motivationalText">{{ motivationalText }}</p>
    </div>
  </div>

  <div class="ccq-report-container" id="cycStart">
    @if (searchResult) {
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <div class="expansion-header-content">
              <h3>{{ searchResult['registrationNumber'] ?? searchResult['registrationNumber_ie'] }} Vehicle Info</h3>
            </div>
          </mat-expansion-panel-header>

        <app-vehicle-data [data]="searchResult"></app-vehicle-data>

        </mat-expansion-panel>
      </mat-accordion>
      <h2>What You Need To Know Now</h2>
      <div class="checksContainer">
      @for (col of [1,2]; track col) {
        <mat-accordion multi="true">
          @for (check of checks | keyvalue: origOrder; track check.key) {
            @if (check.value[curLoc] && check.value.col == col) {
              <mat-expansion-panel class="checkPanel" [expanded]="false" [disabled]="check.value.loading">
                <mat-expansion-panel-header>
                  <div class="expansion-header-content">
                    <h3>{{ check.value.title }}</h3>
                    @if (check.value.loading) {
                      <mat-progress-spinner mode="indeterminate" diameter="16"></mat-progress-spinner>
                    } @else {
                      <div class="trafficLight" [ngClass]="check.value.result?.status"></div>
                    }
                  </div>
                </mat-expansion-panel-header>
                <div class="check-content">
                  @for (txt of check.value.result?.text; track txt) {
                    <p>{{ txt }}</p>
                  }
                  @if (check.key == 'imported' && check.value.result?.tableData?.['Previous VRM']) {
                    <p>Click here to run a new history check on the previous license plate: <a [routerLink]="['/home/cyc', {url: check.value.result?.tableData['Previous VRM']}]">{{check.value.result?.tableData['Previous VRM']}}</a></p>
                  }
                  @if (check.value.result?.data) {
                    @if (check.key == 'equivalent') {
                      <div role="table" class="searchResTable equivalents">
<!--                        <div role="rowheader" class="rowheader">Version</div>-->
                        <div role="rowheader" class="rowheader">Year</div>
                        <div role="rowheader" class="rowheader">Mileage</div>
                        <div role="rowheader" class="rowheader">Price</div>
                        <div role="rowheader" class="rowheader">Link</div>
                        @for (vehicle of check.value.result?.data | keyvalue: origOrder; track $any(vehicle.value).id) {
<!--                          <div role="columnheader">{{ $any(vehicle.value).model }}</div>-->
                          <div role="cell">{{ $any(vehicle.value).yearOfRegistration }}</div>
                          @if ($any(vehicle.value).mileage) {
                            <div role="cell">{{ Number($any(vehicle.value).mileage) | number }} miles</div>
                          } @else if ($any(vehicle.value).kms) {
                            <div role="cell">{{ Number($any(vehicle.value).kms) | number }} kms</div>
                          } @else {
                            <div role="cell">Unknown</div>
                          }
                          <div role="cell">{{ Number($any(vehicle.value).price) | currency: currency : 'symbol' }}</div>
                          <div role="cell">
                            @if ($any(vehicle.value).gone) {
                              Expired
                            } @else {
                              <a [href]="FcfService.getSource(vehicle.value).url" rel="noopener noreferrer" target="_blank">{{FcfService.getSource(vehicle.value).source}}</a>
                            }
                          </div>
                        }
                      </div>
                    }
                  } @else if (check.value.result?.tableData) {
                    <div role="table" class="searchResTable valsRight">
                      @for (res of check.value.result?.tableData | formatTechSpecs | keyvalue: origOrder; track res.key) {
                        @if (res.value) {
                          <div role="rowheader" class="rowheader">{{ res.key }}</div>
                          <div role="cell" class="value">{{ res.value }}</div>
                        }
                      }
                      @if (check.key == 'identity' && searchResult["vin"]) {
                        <div role="rowheader" class="rowheader">VIN</div>
                        @if (vinRevealService.showVin) {
                          <div role="cell" class="value">{{ searchResult["vin"] }}</div>
                        } @else {
                          <div role="cell" class="value"><button (click)="vinRevealService.showVinDialog()">Show VIN</button></div>
                        }
                      }
                    </div>
                  } @else if (check.value.error) {
                    <p class="error">{{ check.value.error }}</p>
                  }
                </div>
              </mat-expansion-panel>
            }
          }
        </mat-accordion>
      }
      </div>
    }
  </div>
  @if (searchResult) {
    <div class="disclaimer-container">
      <app-disclaimer></app-disclaimer>
      <button class="bug" mat-raised-button color="primary" (click)="bug.report('Check Your Car', {vehicleData: searchResult, checks: checks})"><mat-icon>flag</mat-icon> Report an issue with this report</button>
    </div>
  }
</section>

@if (searchResult) {
  <app-ffl-content currency="gbp" [avgCars]="10" [avgPrice]="Number(this.searchResult?.['priceGBP'])"></app-ffl-content>
}
<section class="userActivity" [hidden]="userActivityComponent.activity.data.length === 0">
  <h2>Recent checks</h2>
  <div class="activity-container">
    <app-user-activity #userActivityComponent [entries]="10" endpoint="cyc"></app-user-activity>
  </div>
</section>
@if (searchResult) {
  <section class="hero">
    <div class="prods">
      <app-products [exclude]="'cyc'"></app-products>
    </div>
  </section>
}
