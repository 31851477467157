import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {DecimalPipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import { FcfService } from "../../../services/fcf.service";
import {formatRes} from "../../../pipes/format-res.pipe";

@Component({
  selector: "equiv-cars",
  templateUrl: "../equiv-cars/equiv-cars.html",
  styleUrl: "../equiv-cars/equiv-cars.scss",
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, DecimalPipe, KeyValuePipe, NgForOf, NgIf, formatRes, MatButtonModule, MatDialogActions],
})
export class EquivCarsDialog {
  protected readonly Number = Number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  protected readonly FcfService = FcfService;
}
